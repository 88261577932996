/* src/About.css */
.about-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-width: 900px;
    margin: auto;
  }
  .about-image {
    flex: 1;
    padding: 10px;
    opacity: 0;
    transform: translateY(100px);
  }
  
  .about-image.fade-in {
    animation: fadeIn 1s forwards;
  }
  
  .about-image img {
    width: 100%;
    border-radius: 10px;
  }
  
  .about-content {
    flex: 2;
    padding: 20px;
  }
  
  .about-content h2{
    opacity: 0;
    transform: translateY(20px);
    color:  #FFB4C2;
  }
   .about-content p {
    opacity: 0;
    transform: translateY(20px);
    color:  #170f10;
  }
  
  .about-content h2.fade-in, .about-content p.fade-in {
    animation: fadeIn 1s forwards;
  }
  
  .about-content h2.fade-in {
    animation-delay: 0.5s;
  }
  
  .about-content p:nth-of-type(1).fade-in {
    animation-delay: 1s;
  }
  
  .about-content p:nth-of-type(2).fade-in {
    animation-delay: 1.5s;
  }
  
  .about-content p:nth-of-type(3).fade-in {
    animation-delay: 2s;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  