/* src/Home.css */
.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.card-wrapper {
  opacity: 0;
  transform: translateY(20px);
}

.card-wrapper.fade-in {
  animation: fadeIn 1s forwards;
}

.card-wrapper:nth-of-type(1).fade-in {
  animation-delay: 1s;
}

.card-wrapper:nth-of-type(2).fade-in {
  animation-delay: 1.2s;
}

.card-wrapper:nth-of-type(3).fade-in {
  animation-delay: 1.2s;
}

.card-wrapper:nth-of-type(4).fade-in {
  animation-delay: 1.2s;
}

.card-wrapper:nth-of-type(5).fade-in {
  animation-delay: 1.2s;
}

h1, h4 {
  opacity: 0;
  transform: translateY(20px);
}

h1.fade-in {
  animation: fadeIn 0.5s forwards;
  animation-delay: 0.2s;
}

h4.fade-in {
    animation: fadeIn 0.5s forwards;
    animation-delay: 0.2s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
