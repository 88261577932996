/* src/Contact.css */
.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    margin: 170px auto; /* Center the container and add top margin */
    max-width: 600px;
    background-color: #FDFFD2;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .contact-container.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  .contact-container h2 {
    margin-bottom: 20px;
    color: #DA7297;
    font-size: 24px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .contact-form label {
    margin-top: 10px;
    margin-bottom: 5px;
    color: #667BC6;
    font-weight: bold;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.2s;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    border-color: #80bdff;
    outline: none;
  }
  
  .contact-form textarea {
    resize: vertical;
    height: 150px;
  }
  
  .contact-form button {
    width: 100px;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    background-color: #80bdff;
    color: white;
    cursor: pointer;
    align-self: center;
    transition: background-color 0.2s;
  }
  
  .contact-form button:hover {
    background-color: #DA7297;
  }