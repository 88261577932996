/* src/components/Card.css */
.card {
  border: 1px solid #DA7297;
  border-radius: 10px;
  background-color: #FDFFD2;
  padding: 16px;
  margin: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, max-height 0.3s ease;
  max-width: 600px;
  overflow: hidden;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
  transition: all 0.8s ease;
}

.card.expanded {
  max-height: none; /* Allow the card to expand fully */
}

.card-content-container {
  display: flex;
  align-items: flex-start;
}

.card-image {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  margin-right: 16px;
}

.card-details {
  flex: 1;
}

.card-title {
  margin: 0;
  font-size: 1.5em;
  color: #DA7297;
}

.card-skills {
  font-size: 1em;
  color: #DA7297;
  margin: 4px 0;
}

.card-content {
  font-size: 1em;
  color: #667BC6;
  margin: 8px 0;
}

.card-extra-content {
  margin-top: 16px;
  font-size: 1em;
  color: #667BC6;
}

.card-extra-content img {
  width: 100%;
  height: auto;
  display: block;
  margin: 10px 0; /* Optional: Add some margin for spacing */
}
.card-github-link {
  display: inline-block;
  margin-top: 10px;
  color: #0366d6;
  text-decoration: none;
  font-weight: bold;
}

.card-github-link:hover {
  text-decoration: underline;
}