.App {
  text-align: center;
  background: linear-gradient(180deg, #667BC6 0%, #FDFFD2 100%);
}

#navbar{
  display:flex ;
  align-items:flex-end;
  justify-content: flex-end;
}
#navbar li{
  list-style:none;
  padding: 0 20px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #DA7297;
}


header {
  text-align: center;
  padding: 20px 0;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  text-decoration: none;
  color: #FDFFD2;
  font-weight: bold;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
}

nav ul li a:hover {
  background-color: #FFB4C2;
}
h1 {
  font-size: 6em;
  color: #FFB4C2;
  line-height: 1.2;
}
h4{
  font-size: 1.2em;
  color: #FDFFD2;
  margin-top: 10px;
}
.cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
}